<template>
	<div>
		<a-layout>
			<div class="content-header">
				<a-affix :offset-top="0" style="width: 100%;">
					<a-row type="flex" justify="space-between">
						<a-col span="12">
							<common-page-btn @save="$refs.carForm._show()" @category="category"></common-page-btn>
						</a-col>
						<a-col span="12">
							<a-row type="flex" align="middle" justify="end" :gutter="3">
								<a-col span="12">
									<a-input v-model="param.keyword" allowClear search @pressEnter="getCarList"
										@on-clear="getCarList" placeholder="关键词查询"></a-input>
								</a-col>
								<a-col span="6" v-if="getOrganId() == 0">
									<a-select v-model="param.organId" style="width:100%" @change="e => {
																			this.getCarList();
																		}
																			" placeholder="根据单位筛选">
										<a-select-option value="">所有单位</a-select-option>
										<a-select-option :value="organ.organId" v-for="(organ, index) in organList"
											:key="index">{{ organ.name
																						}}</a-select-option>
									</a-select>
								</a-col>
							</a-row>
						</a-col>
					</a-row>
				</a-affix>
			</div>
		</a-layout>

		<a-table :columns="columns" :defaultExpandAllRows="true" :scroll="{ x: '100%' }" :pagination="false"
			:indentSize="25" :style="{ background: '#FFF' }" size="small" row-key="carId" border ref="list"
			:loading="loading" :data-source="records">
			<!-- 车牌号 -->
			<template slot-scope="row" slot="carNumber">
				<a-button-group shape="circle" size="small">
					<a @click="Detail(row)">{{ row.carNumber }}</a>
				</a-button-group>
			</template>
			<!-- 所属单位 -->
			<template slot-scope="row" slot="organId">
				<div>{{ organList.find(item => item.organId == row.organId).name }}</div>
			</template>
			<!--操作-->
			<template slot-scope="row" slot="action">
				<a-button-group shape="circle" size="small">
					<a-tooltip title="编辑"><a-button type="link" icon="edit"
							@click="$refs.carForm._show(row)"></a-button></a-tooltip>
					<a-tooltip title="删除"><a-button type="link" v-has="'car:del'" icon="delete"
							@click="del(row.carId)"></a-button></a-tooltip>
				</a-button-group>
			</template>
		</a-table>
		<a-row>
			<a-col span="12" offset="6" style="text-align: center; padding: 20px 0;">
				<a-pagination :defaultPageSize="param.limit" :total="data.total" @change="getNext"
					@showSizeChange="setLimit" :current="data.current" />
			</a-col>
		</a-row>
		<car-form ref="carForm" @success="doRefresh"></car-form>
		<car-category ref="category"></car-category>
		<car-detail ref="detail"></car-detail>
	</div>
</template>

<script>
import apiUtil from '@/common/apiUtil';
import carForm from './components/carForm.vue';
import utils from '@/common/utils';
import CarCategory from './components/carCategory.vue';
import carDetail from './components/carDetail.vue';
export default {
	components: { carForm, CarCategory, carDetail },
	data() {
		return {
			data: {},
			records: [],
			loading: false,
			organList: apiUtil.getOrganList(),
			pageSizeOptions: ['10', '20', '30', '40', '50'],
			pageSize: 10,
			total: '',
			param: {
				current: 1,
				limit: 20,
				keyword: '',
				organId: apiUtil.getOrganId('')
			},
			columns: [
				{ title: '车牌号', scopedSlots: { customRender: 'carNumber' }, width: 80, ellipsis: true, align: 'center' },
				{ title: '状态', dataIndex: 'status', width: 80, ellipsis: true },
				{ title: '类别', dataIndex: 'carCategory.categoryName', width: 100, ellipsis: true },
				{ title: '备注信息', dataIndex: 'remark', align: 'left', width: 200 },
				{ title: '操作', scopedSlots: { customRender: 'action' }, align: 'center', width: 100 }
			]
		};
	},
	mounted() {
		this.getCarList();
	},
	methods: {
		...apiUtil,
		// 详情
		Detail(e) {
			console.log(e);
			this.$refs.detail.show(e.carId);

		},
		// 分类设置
		category() {
			this.$refs.category._show();
		},
		// 获取车辆数据
		getCarList() {
			this.loading = true;
			this.http
				.get('/platform/car/list', this.param)
				.then(ret => {
					this.data = ret.data;
					this.records = ret.data.records;
					this.total = data.total;
					this.param.current = data.current;
					this.loading = false;
				})
				.catch(err => {
					this.loading = false;
				});
		},
		// 删除车辆
		del(id) {
			this.utils.confirm('删除后无法恢复，是否确认?').then(() => {
				utils.showSpin();
				this.http.delete('/platform/car/del/' + id).then(ret => {
					if (ret.code == 200) {
						this.utils.success('操作成功！').then(() => {
							this.getCarList();
							// console.log(1111);
						});
					}
				});
			});
		},
		// 筛选条件变化重新获取数据
		doRefresh() {
			this.param.current = 1;
			this.getCarList();
		},

		//设置每页的显示条数
		setLimit(current, limit) {
			this.param.page = 1;
			this.param.limit = limit;
			this.getCarList();
		},
		//页码切换
		getNext(page) {
			this.param.page = page;
			this.getCarList();
		}
	}
};
</script>

<style></style>
